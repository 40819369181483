<template>
  <v-container fluid pa-4>
    <v-card v-if="$route.query.index">
            <v-overlay
            :opacity="1"
            :value="overlay"
            >
            <v-progress-circular indeterminate size="64">
                Loading...
            </v-progress-circular>
            </v-overlay>
        </v-card>
    <v-layout v-if="error != null" row wrap>
      <v-flex xs12 sm12 md12>
        <template>
          <v-alert :value="true" type="error">{{ error }}</v-alert>
        </template>
      </v-flex>
    </v-layout>
    <v-layout align-center justify-center>
      <!-- <v-flex offset-xs4 pt-12> -->
      <v-card
        class="elevation-8"
        :height="!maintenance ? '500' : '350'"
        width="400"
        :style="`align: center; top: 20%; ${maintenance?'position:absolute;':''}`"
      >
        <br />
        <v-divider color="black"></v-divider>
        <!--   class="mr-5"-->
        <!-- {{currentVersion}} -->
        <center><img style="width:99%;" src="/logo.png" /><span v-if="!maintenance"> Ver. {{ currentVersion ? currentVersion : '-'}} </span></center>
        <!-- <img src="/testlogo.png" class="responsive mt-5" />
        <h1 class="font-weight-light overline"><center>Document Control System</center></h1> -->
        
        <v-divider color="black"></v-divider>
        <!-- <br /> -->
        <br />

        <v-card-text v-if="!maintenance">
          <v-form>
            <v-text-field
              autofocus
              prepend-inner-icon="mdi-account"
              name="login"
              label="Mail Address"
              type="text"
              @change="getInfo()"
              v-model="mailAddress"
              color="blue-grey darken-1"
            ></v-text-field>

            <v-text-field
              @keyup.enter="go()"
              id="password"
              prepend-inner-icon="mdi-lock"
              name="password"
              label="Password"
              type="password"
              v-model="userPass"
              color="blue-grey darken-1"
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-card-actions v-if="!maintenance">
          <v-btn rounded color="#455A64" @click.prevent="go()" dark block>
            <v-icon>mdi-logout</v-icon>Login
          </v-btn>
        </v-card-actions>

        <!---2024-04-17-->
        <div v-if="sessionExpired && !maintenance" class="sessionExpiredtext">
          セッションの有効期限が切れました。 もう一度ログインしてください。 
        </div>

        <!-- NOTE: FOR ONGOING MAINTENANCE 2024-09-24 -->
        <v-card-text v-if="maintenance">
          <h1 style="color:red;">Maintenance Notice</h1>
          <p>We are currently undergoing maintenance. We apologize for any inconvenience and appreciate your understanding.</p>
          <p>Thank you!</p>
        </v-card-text>

        <!-- NOTE: hide user's manual in login timing instructed by Mr Tominaga 2024-03-05 -->
        <!-- <v-layout>
          <v-spacer></v-spacer>
          <span @click="goToUserManual()" style="cursor: pointer" class="pr-1">
            <v-icon color="#455A64"> mdi-book-open-page-variant </v-icon>&nbsp;
            <u> User's Manual</u>
          </span>
        </v-layout> -->
      </v-card>
      <!-- </v-flex> -->
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
// import shortid from 'shortid'
import Swal from "sweetalert2";
import md5 from "md5-hex";
import { mapGetters } from 'vuex'; //2024-04-17
import store from '@/store'; //2024-04-17
export default {
  name: "Login",
  data() {
    return {
      maintenance: false,
      mailAddress: "",
      userPass: "",
      reqInfo: null,
      error: null,
      overlay: true,
      userLogData: {},
      currentVersion: '',
      registeredDomains: [] // 2022-06-14
      // api: process.env.VUE_APP_URL,
    };
  },
  computed: {
    ...mapGetters(['sessionExpired']), //2024-04-17
  },
  created() {
    //2024-04-17
    if(this.maintenance){
      return false
    }
    window.addEventListener('beforeunload', () => {
    store.dispatch('resetSessionExpired');});
    let loginTime = new Date().getTime();
    this.$store.dispatch('setLoginTime', loginTime);
    let expiredTime = loginTime + (7 * 24 * 60 * 60 * 1000);
    this.$store.dispatch('setExpiredTime', expiredTime);

    this.getCurrentSystemVersion()
    this.LoadAllRegisterDomains() // 2022-06-14
    if(this.$route.query.index){
      console.log(this.$route.query.index)
      axios.defaults.headers.common['x-api-key'] =
      'H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3'
      // let url = `https://v7ck8ajvp1.execute-api.us-east-2.amazonaws.com/prod/getAllUsers`
      let url = `${this.api}getAllUsers`

      axios.get(url).then(r => {
       let user = r.data.filter(r => {
          return md5(r.MailAddress) == this.$route.query.index && this.$route.query.id == md5(r.UserId)
        })
        if(user.length > 0){
          console.log(user)
          let url2 = `${this.api}getUser/${user[0].MailAddress}`;
          let toCheck = {
            pass: 'AUTOLOGIN',
          };
        axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
        axios.post(url2, toCheck).then(res => {
          if (res.data != "error" && res.data != "User does not exists!") {
          // let date = moment();
          // this.setExpiryDate(date.format("YYYY-MM-DD"));
          let info = {
            name: res.data.user_info.Name,
            id: res.data.user_info.MailAddress,
            isAdmin: res.data.user_info.isAdmin,
            isRulebookUser: res.data.user_info.isRulebookUser,
            isGCUser: res.data.user_info.isGCUser,
            isIchijoUser: res.data.user_info.isIchijoUser,

            isSalesUser: res.data.user_info.isSalesUser // 2023-04-19
          };
          this.ADD_INFO(info);
          this.$store.commit("AUTH_SUCCESS", res.data.accessToken);
          this.$router.push("/");
        } else if (res.data == "User does not exists!") {
            Swal.fire({
              icon: "error",
              title: res.data,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Invalid Password!",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        }else{
          Swal.fire({
            icon: "info",
            title: "Unregistered Account.",
            showConfirmButton: true,
            text: `This Account is not registered in Rulebook System`,
          });
          this.overlay = false
          this.$router.replace({'query': {}});
        }
      })
    }
  },
  methods: {
    getCurrentSystemVersion () {
      axios.defaults.headers.common['x-api-key'] =
        'H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3'

       let url = `${this.api}SystemLogs/getAll`
       axios.get(url).then(res=>{
           if( this.appDev == 'production') {
                 
                 this.currentVersion = res.data.filter(rec=>{
                      return rec.isLatestActualVersion == true 
                 })
                //  [0].version

                  let  compareVersions =(a, b) =>{
                      const versionA = a.version.split('.').map(Number);
                      const versionB = b.version.split('.').map(Number);

                      // Compare each component of the version number
                      for (let i = 0; i < Math.min(versionA.length, versionB.length); i++) {
                        if (versionA[i] > versionB[i]) return -1;
                        if (versionA[i] < versionB[i]) return 1;
                      }

                      // If all components are equal, longer version numbers come first
                      return versionA.length - versionB.length;
                    }
                    // Sort the array of objects by version number
                    this.currentVersion =  this.currentVersion.sort(compareVersions)[0].version
                    console.log(this.currentVersion)
            }
            else{
                 this.currentVersion = res.data.filter(rec=>{
                      return rec.isLatestTestVersion == true 
                 })
                 //[0].version
                let  compareVersions =(a, b) =>{
                      const versionA = a.version.split('.').map(Number);
                      const versionB = b.version.split('.').map(Number);

                      // Compare each component of the version number
                      for (let i = 0; i < Math.min(versionA.length, versionB.length); i++) {
                        if (versionA[i] > versionB[i]) return -1;
                        if (versionA[i] < versionB[i]) return 1;
                      }

                      // If all components are equal, longer version numbers come first
                      return versionA.length - versionB.length;
                    }

                    // Sort the array of objects by version number
                   this.currentVersion =  this.currentVersion.sort(compareVersions)[0].version;
                 
            }
       })
       .catch(err=>{
          console.log(err.message);
          alert('Error in fetching system logs version');
       })
    },
    ////HAZEL////
    loadUserLogs(){
      // let url = `${this.api}getUser/${this.mailAddress}`
      // let url = `${this.api}userLogs/getLog/${this.userLogData.employeeCode}/${this.userLogData.id}`
      // axios.get(url).then(res => {
      //   // console.log('---------------111111---------------------');
      //   // console.log(res.data);
      //   // console.log('------------------------------------');
      //   if(res.data){
      //     console.log('UPDATE CODE')
      //   }else{
      //     console.log('INSERT DATA')
      //     // this.insertNewLog()
      //   }
      // })
    },
    insertNewLog(){
      let url = `${this.api}userLogs/newLog`
      let tempDate = {}
      tempDate.date = moment().format('l')
      tempDate.count = 1
      this.userLogData.accessDates.push(tempDate)
      console.log('USER LOG DATA',this.userLogData)
      axios.post(url,this.userLogData).then(r => {
        console.log(r.data)
      })
    },
    async LoadAllRegisterDomains(){  // 2022-06-14
       try{
         const url = `${this.api}getAllUsersDomain`
         let response = await axios.get(url);
         this.registeredDomains = response.data.map(rec=>{
            return rec.DomainName
         })
        //  console.log('line693', this.registeredDomains )
       }catch(err){ 
         console.log(`line696 ${err.message}`)
       }
       
    },

    getInfo: function () {
      // if()
      if (this.mailAddress.includes("@")) {
        let index = this.mailAddress.indexOf("@");
        // console.log('index',index)
        let cnd = this.mailAddress.substring(index, this.mailAddress.length);
        console.log(cnd)
        // console.log(cnd)
        // if (
        //   // cnd != "@hrd-s.com" &&
        //   // cnd != "@ichijo.co.jp" &&
        //   // cnd != "@ichijousa.com" && 
        //   // cnd != "@ichijo-gunma.com" && 
        //   // cnd != "@gmail.com" && 
        //   // cnd != "@ichijo-gunma.com" 

        //   cnd != "@hrd-s.com" &&
        //   cnd != "@ichijo.co.jp" &&
        //   cnd != "@ichijousa.com" &&
        //   cnd != "@ichijo-gunma.com" &&
        //   cnd != "@shimoden.co.jp" &&
        //   cnd != "@ichijo-sanin.co.jp" &&
        //   cnd != "@takanohome.jp" &&
        //   cnd != "@ichijo-miyagi.co.jp" &&
        //   cnd != "@rose.ocn.ne.jp" &&
        //   cnd != "@ichijo-sendai.com" &&
        //   cnd != "@gmail.com" &&
        //   cnd != "@cd.inbox.ne.jp" &&
        //   cnd != "@takano-ichijo.jp" &&
        //   cnd != "@ichijo-nagaoka.jp" &&
        //   cnd != '@kk-nissan.co.jp' &&   // 2022-06-11 

        //   cnd != '@ichijo-h.co.jp' && // 2022-06-17
        //   cnd != '@yanamoto.co.jp' 
          
        // ) {
        // if ( !this.registeredDomains.includes( cnd ) ) {  // 2022-06-23
        if( this.isValidEmail( this.mailAddress) == false ) {
          Swal.fire({
            icon: "error",
            title: "Invalid Mail Address!",
            showConfirmButton: false,
            timer: 1500,
            // text: "Please Generate First !!",
            
          });
          this.mailAddress = "";
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Invalid Mail Address!",
          showConfirmButton: false,
          timer: 1500,
          // text: "Please Generate First !!",
        });
        this.mailAddress = "";
      }
    },
    go: function () {
      let url = `${this.api}getUser/${this.mailAddress}`;
      
      // console.log('line286', url)
      let toCheck = {
        pass: md5(this.userPass),
      };

      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      axios.post(url, toCheck).then((res) => {
        // console.log('line294',res.data)

        if (res.data != "error" && res.data != "User does not exists!") {
           //NOTE:  for checking the account is deleted 2024-03-04
           axios.get(`${this.api}getUserDetails/${this.mailAddress}`).then(async (info)=>{
              if(info.data.isDelete){
                Swal.fire({
                  icon: "error",
                  title: 'This account has already been deleted.',
                  showConfirmButton: false,
                  timer: 1500,
                });
                return false
              }
              else{
                let toInsertUserLog = {
                       MailAddress: this.mailAddress, // 2022-02-07
                       count: 1,
                       name: res.data.user_info.Name  // 2022-02-14
                 }
               // let date = moment();
               // this.setExpiryDate(date.format("YYYY-MM-DD"));
               // console.log();
               // =========================== OLD ============================= //
               // console.log('EMAIL', res.data.user_info)
               // let info = {
               //   name: res.data.user_info.Name,
               //   id: res.data.user_info.MailAddress,
               //   isAdmin: res.data.user_info.isAdmin,
               //   employeeCode: res.data.user_info.UserId,
               //   // isAdmin: true,
     
               //   isRulebookUser: res.data.user_info.isRulebookUser,
               //   isGCUser: res.data.user_info.isGCUser,
               //   isIchijoUser: res.data.user_info.isIchijoUser
               // };
               // // // ////DATA FOR USER RANK////
               // // this.userLogData = {
               // //   user: res.data.user_info.Name,
               // //   email: res.data.user_info.MailAddress,
               // //   accessDates: [],
               // //   id: shortid.generate().substring(0,5),
               // //   employeeCode: res.data.user_info.UserId,
               // // }
               // // console.log('------------------222222------------------');
               // // console.log(this.userLogData);
               // // console.log('------------------------------------');
               // this.ADD_INFO(info);
               // this.$store.commit("AUTH_SUCCESS", res.data.accessToken);
               // this.$router.push("/");
               
               // this.loadUserLogs() // tanskie
               // ================================================================= // 
     
     
               // ====================== tanskie 2022-02-07 ======================= //
               let insertLogUrl = `${this.api}userLogs/insertUserLog`;
     
               axios.post(insertLogUrl, toInsertUserLog ).then(res1=>{
                  if(res1.data) {

                    //2024-04-17
                    let loginTime = new Date().getTime();
                    this.$store.dispatch('setLoginTime', loginTime);
                    let expiredTime = loginTime + (24* 60 * 60 * 1000); //time check interval(nakaset po sa 2hours)
                    this.$store.dispatch('setExpiredTime', expiredTime);
                   
                     let info = {
                       name: res.data.user_info.Name,
                       id: res.data.user_info.MailAddress,
                       isAdmin: res.data.user_info.isAdmin,
                       employeeCode: res.data.user_info.UserId,
                       // isAdmin: true,
                       isIchijo: res.data.user_info.isIchijo,
                       isRulebookUser: res.data.user_info.isRulebookUser,
                       isGCUser: res.data.user_info.isGCUser,
                       isIchijoUser: res.data.user_info.isIchijoUser,
                       isGCPortalUser: res.data.user_info.isGCPortalUser, // 2022-09-06
                       isIncharge: res.data.user_info.isIncharge, // 2022-09-06
                       isSalesUser: res.data.user_info.isSalesUser, // 2023-04-19
                       originalMailAddress: res.data.user_info.OriginalMailAddress, // 2022-02-12
                       loginTime: loginTime, //2024-04-17
                       expiredTime: expiredTime, //2024-04-17
                     };
                     // // ////DATA FOR USER RANK////
                     // this.userLogData = {
                     //   user: res.data.user_info.Name,
                     //   email: res.data.user_info.MailAddress,
                     //   accessDates: [],
                     //   id: shortid.generate().substring(0,5),
                     //   employeeCode: res.data.user_info.UserId,
                     // }
                     // console.log('------------------222222------------------');
                     // console.log(this.userLogData);
                     // console.log('------------------------------------');
                     this.ADD_INFO(info);
                     this.$store.commit("AUTH_SUCCESS", res.data.accessToken);
                     this.$router.push("/");
                     
                     this.loadUserLogs() // tanskie
                  }
               }).catch(err1=>{ console.log(err1.message)})
              }
           })

        } else if (res.data == "User does not exists!") {
          Swal.fire({
            icon: "error",
            title: res.data,
            showConfirmButton: false,
            timer: 1500,
          });
          this.userPass = "";
          this.mailAddress = "";
        } else {
          Swal.fire({
            icon: "error",
            title: "Invalid Password!",
            showConfirmButton: false,
            timer: 1500,
          });
          this.userPass = "";
        }
      });
    },
  },
};
</script>
<style  scoped>
img {
  image-rendering: auto;
  image-rendering: crisp-edges;
  image-rendering: pixelated;
  -webkit-transform: scale(0.999);
  transform: scale(0.999);
  image-rendering: -webkit-optimize-contrast;
}

/*2024-04-17*/
.sessionExpiredtext{
  color: red;
  text-align: center;
  font-size: 11px;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.responsive {
  width: 100%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>